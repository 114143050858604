import "../core/src/components/page-list/collections/collection-list-item.css.ts.vanilla.css!=!../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../core/src/components/page-list/collections/collection-list-item.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA8VWbWvbMBD+nl8hCoUGquK8rlPY/scYYyjS2dYqS0aSm3Sj/31IshPZcZplK+xbfLmX5557fOeH7zMuqtkeMvRrghAXtpb0haBcwn4zQYhpqQ1Bz9TcYUzzXCjADvYO10ZU1Lzg4DD1riWIonQErZZ1CPUpsC2NUE8EZd6yE9yVBM2y7NY/UikKhYWDyhJknQHHSm93hiornNCKoC1lT4XRjeKxEsoe5vYe6Zoy4V7Ckw/Rz2ByqXcElYJzUAF6Y6zHziGnjXQRgJSYlVQVQLocm8nr5OHAAil9qsDFsPSAheB4aD/N8ZVTRzGTgj3RrYRPzjTwLaTsINVaKAemFzYLHh2Jy1UkcasNB4MN5aKxnrpoPplTj0sGMXvaw3n0OBdSAp/Gcn5klHsme/4VqKb9JzhWdI/bcS7WLahKqM42j7akvXlor9bdYA1I6sQzxGSmEApLyB1B+DFm65jo1KIbJ4UCgpRWIaqmnAtVtGGPsV7knhtaFAn3RxRkC7k2EKehlQPlCLq52fSw0a3VsnGhitM1QasIIQgz16Yi8aekDr7c4VV2GziJQFKlL/uttI+pvuNfZ1R9QYG1pAxKLXmiwxPFzGPNNvUBXvIeRAj36ftwkcUgn5TLw7SG9WZn8yVGoYqTGiHtEfbD6m8TjcFctDA7uY6BPr7Q6DMiuTDWYVYKyaOSe+qbrQdyDzGSjoaYiOFxELJInQjK0Oq6d72gdbcgkqzL8b1+Jsfpzs61ctiKnzAQX7CXeNHffavxaiEtFwZYVD3TsqnUCY5Y3VHjNn0mIr29rcOoZHd+NyCM1st6P+3qhOkhtCuFA2xrysLK2Blae3NjwWALEpjrNkkCfx1FN3JM3qTBVtODz65V2DrL3oCRAA3X9FgSpBS1FfZIjgWZJ/cxgfvhLNyzZ9sC04r3D/ebve3tHzL7D308XqfRy4ocJ0AwrdK2B1pPAH18P0Atf1cNJFH+4Bh2p7b3JZUAp/8X+Mj68BYvkVQqw/6SBrZXNfCjsU7kHkF7yUM5UPzCfNn7VYmrDIflux7/8H39DY+5qINlCwAA\"}!../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
import "../core/src/components/page-list/docs/page-list-item.css.ts.vanilla.css!=!../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../core/src/components/page-list/docs/page-list-item.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA71W247bIBB9z1eglVbaSEvkXJsStf9RVVVFYGzTxWABTrKt9t8rg51gx0madtU3ezzMnDlzmPHk+3Sh+DTdJejXCCEubCnpK0GphMNmhBDTUhuCdtQ8YUzTVCjADg4Ol0YU1Lxi7zCuXXMQWe4IWi5Kf7QOgW1uhHohKKkte8FdTtA0SR7rVypFprBwUFiCrDPgWF7bnaHKCie0ImhL2UtmdKV4yISSycw+I11SJtyrf6uP6B2YVOo9QbngHJSHXhlbY+eQ0kq6AEBKzHKqMiBtjM3obTQ5skDyOpTnop+6x4J3PJYfx/jKqaOYScFe6FbCJ2cq+OZDtpBKLZQD0zk29R4tiYtlIHGrDQeDDeWisgStg/WsTR0qGYTgcQmXweNUSAl8HLLVHaO8JrLjX4Cqmi/esaAH3HRzvkoCqEKo1jYLtqi6ma+u1G1fDUjqxA5CMJMJhSWkjiDclNgS0YpFV04KBQQprfypknIuVNYcW4d8gXpuaJZF1J9QkC2k2kBohlYOlCPo4WHTwUa3VsvK+SxOlwQtAwSvy1SbgoRHSR18ecLL5NFzEoDEQl90S2leY3mHTxdEfUOApaQMci15JMMzwcxCzib0EV50DQKE5/g63GTRyyfm8titfr7pxXiRUajsLIcPe4I9Wf5toCGY8wZmK9ch0Kf7jD4jkgpjHWa5kDwouaO+6aond39G0sEjJmBY947MYyeCErS8765ntKxvSy/qYnisX4hxPrJTrRy24if0xOftOZ53R99yOJsPy4UBFlTPtKwKdYYjZHfUuE2XiUBvZ+owKtlTPRsQRqtFeRi3eXz3ENrnwgG2JWV+ZOwNLWtzZcFgCxKYaydJBH8VRDewS67SYIvx0WffKGyVJFdgRED9Mj2lBClFaYU9kWNBptF6jOB+uAj34ta2wLTi3b19tbaD/UNm/6GO9X0ava3IYQIE0youu6f1CNDH9wPU8HdXQyLl95Zhu2o7P1IRcPr/gJ///w0Mj9pSCyQWynl11y/l9q6aflTWibSG1Sx3jwEUv9Fy9n5ZwnTDfh6vhn+F334DRtyk13cLAAA=\"}!../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
import "../core/src/components/page-list/tags/tag-list-item.css.ts.vanilla.css!=!../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../core/src/components/page-list/tags/tag-list-item.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA8VWbW/bIBD+nl+BKlVqpBI5r0uJtv8xTdNE4GyzYLAAN+mm/ffJYCfYsZu1qrRv8eW4e+65hztmP+Zb6Q5PIkG/JwhxYUtJXwhKJZx2E4SYltoQ9EzNA8Y0TYUC7ODkcGlEQc0L9g7T2jUHkeWOoPWq9EfrENjmRqgDQUltOQrucoLmSXJff1IpMoWFg8ISZJ0Bx/La7gxVVjihFUF7yg6Z0ZXiIRNKZgv7iHRJmXAv/qs+op/BpFIfCcoF56A89MrYGjuHlFbSBQBSYpZTlQFpY+wmfyazMwskr0N5Lvqpeyx4x3P5cYxvnDqKmRTsQPcSPjtTwXcfsoVUaqEcmM6xufdoSVytA4l7bTgYbCgXla2pC+arPnW4ZBCixzWMo8epkBL4NKSrW0Z5zWTHvwBVNf94x4KecNPO5aYBVQjV2hbBFpW38OWVum2sAUmdeIYQzGRCYQmpIwhvQ7SWiVYtunJSKCBIaeVPlZRzobLm2DbkC9xzQ7Ms4v6Cguwh1QZCN7RyoBxBd3e7Dja6t1pWzmdxuiRoHSB4YabaFCT8lNTB1we8Tu49JwFIrPRVt5TmM9Z3+GtE1TcUWErKINeSRzq8Uswi5GxCn+FF9yBAeIzvw00WvXxiLs/d6uebj8aLjEJlVzl82Avs2fq9gYZgLhuYrVyHQF8uNPqCSCqMdZjlQvKg5I765pue3P0ZSQePmIBh2zuyjJ0IWr/tpme0bMdDFHM1PNVHYlxP7FQrh634BT3peXuOl93Jt/6HbCGHo8bt4mqThsLOZGFUsof6/iOMNqvyNG1B+g4hdMyFA2xLyvxYOBpa1ubKgsEWJDDXTosI5CYIa2BhvFqsLaZnn2Ojok2SvALDr8lLHpBSlFbYCyMWZBrT39PHqtfLT6PAR5e0BaYV767pV6vcUwvTYSl8TJVRPdu3afO2EoeJEEyruPxeYRGgp48DdLKjeEYbE92F3gpsF2zn/RQBp/8X+MDYqC21QmKl9OuLCti/qYCflXUirRE0+9unA8Vv9Jd9XBamZVUo7IfuZvi5GyUO47/dNb1HQfv9blwDVwvihD0xbQcfls0L56qIvz+nlA4gDAAA\"}!../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
import "../core/src/components/page-list/list.css.ts.vanilla.css!=!../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../core/src/components/page-list/list.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA6VU7W6jMBD8z1NYlU5qpXME95Emrirde5yqamMvYRuwObMQ0lPe/YRpSEjIffUnnmF2dmft2XMC98WPRZuIn5EQWzKcKZHE8YeHSIg0x1aJ5CHaR7MD8dMUsYBWnh1pZxnIopcWClTi8H88BnlXohJkc7IoK3rFDl6B3qy9q61RogF/KyWkaUc4ArL0VIDfSe1y5+9GFj8Hi4aqMoedCl0cupGGPGomZ5XQLq8L2yHebeUaSiWSedmOpL5OS0FOayuJsaiU0GgZ/VChyjzZjRKhUdegT3O3VSIjY9COxO/H4tZZHOGLfyr+UldMaTcQy2i550u05oqzEjTxLnyd1vxugEGm0DhPjE8fxew5+WJNkjaxyrp2xJEbQENF0uIVcJHzZkmXYOhssDDesGUAQ67dZmToiTvHjC1Lg9p56AO8GNhSNVQRo5lUGBFBMzX4Jx4EQukq6ivCqnJ5zWFJPa0zfhvm2e5n2GPzuAyJnUUsxKskay7vFvTDrzK3fWRf41Mo/z+34R1rc4R07atuMlCzG/lcnc5tZIm0s1NXcvW2AX/bD3rv/O+EhgN91cu5xrfh1Tk+RuL25OlaxHHZ3gW9QX7eR5KRwTC7x5sumJs+mYlk99H+FwLa9AtUBQAA\"}!../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var clearLinkStyle = '_1a7mq8x9';
export var colWrapper = '_1a7mq8x5';
export var deleteButton = '_1a7mq8xb';
export var deleteIcon = '_1a7mq8xc';
export var editTagWrapper = '_1a7mq8xa';
export var favoriteCell = '_1a7mq8x8';
export var groupsContainer = '_1a7mq8x3';
export var heading = '_1a7mq8x4';
export var hidden = '_1a7mq8x7';
export var hideInSmallContainer = '_1a7mq8x6';
export var listRootContainer = '_1a7mq8x0';
export var pageListScrollContainer = '_1a7mq8x1';
export var root = '_1a7mq8x2';