import "../component/src/ui/modal/styles.css.ts.vanilla.css!=!../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../component/src/ui/modal/styles.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA61W62rjOBT+n6c4WyikUAUnTTMddwtlF/YFdmEZlrIcW8eJprJkJDmXLn33RZLjOM5lmqG/Es71+85Nfn6lTWGwJAv/juvX9axcT+G/AUBhdBn+AOgKc+E2KSSPA4D3AYDTfdU4qt4Hz4cB788HBHAGlS20KdP4V6Kjb0M2ub4Bm6OkYTL6Orv5QfJTYZJtkPHNGYyzEPMw9E8hPEb1EzB+6dfxeLBxklwfFutU4jP5Hj6WL/losg6w98FomyULPpW2wgmtUijEmri3EsqSazqQYf46N7pWnOVaapPCEs2QMSwKoYh11KXmKKNRyPXGhOK07jk00mgd7FCJEiOC3SKM75PSQqHNCg23j3uwR96DKa0oEOj4e1nPttSZkNHwB1QkbsiwaN/C68TKP1IuLmwlcZNCIWkd2EkxV0w4Km0KOSlHxou/19aJYsNyrRwp11V9rG5dYP9wdMiKWkpmc0Oknq6cqenqJSJGzoWap5DAL6KstHGo3H6ACyra2BbI6U+/OH/pqu+zuz6xi3mdiZxl9CbIDJPRdHILyS0ko/uHWxjvjwArhJSeI6V7zY8Mt4HvXuAcntHSMcydWEY2S0ErFpYhNI4pLGkH8usxdlYKTr9p55oFPELuCySjyZ0FQkufQ6GT9CIG6KOnad9ISz5sSd6cojH7vB6dh4AnITxcWsm2cDyEZG1RkxQe9wTjvmASBSvB3WK7YK13oLkgMV+4vi6WoBSKHddPoh7XrT5Hmfu7u1wAg7tJtW4tmuStwQoYTJLGINNrZsVb2NdMG06GZTockkIr51XUOwxBnqGlm9Zq1WCYJuEmSW+2xTUezR67R8Fnhsm0Cjl2x82QRD9/lz0BeklG4oZVqKj7FARWC+R61fOtdOV9GmVjG1gb5KK2KYwnEZquneexu0inj965+QgFPxiS0KeDSWmlna61/t1et4Y97MnetFLv/cDMalm7UGKnqxQmDVUTo/rO/NR7UMSvh3PzsmCzw3GZHR2X6X2ka+ZCsSycp21XOjnnIedKG84yQ/iaQvhhXhI2biEcMVthTilUhtjKYBX6uiRTSL0Kgn2vTvhFCH/wuB68ol7BSPEzL28z+SyUfNrUuKEXZFg77WVzjyc2oYtkFCr+h9aOzN/CLX5fCMkNqe5bGwMd8TW0JGPjJASsXBjKm5XTK9boT3OzDs3+4y1CsC6B7cr0eradp87UXu91YLPj3vlmmPZIfD/eij6bXMu6VLtFDVV7uipQWrp6OfioEr49jJaknO3v+L5rf737vpFCB/DrZYCbxm8n/LkkLhDiiQFUHIbhFMCvTzDz4xPftR6h3vR1x3I3JNvGkFoOLRbE0BCy8DXZ6G53D8P74P1/5Pge4TIOAAA=\"}!../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var closeButton = '_1ukx6mxe';
export var confirmModalContainer = '_1ukx6mxj';
export var confirmModalContent = '_1ukx6mxi';
export var heightVar = 'var(--_1ukx6mx1)';
export var minHeightVar = 'var(--_1ukx6mx2)';
export var modalContent = '_1ukx6mxd';
export var modalContentWrapper = '_1ukx6mxc';
export var modalDescription = '_1ukx6mxg';
export var modalFooter = '_1ukx6mxh';
export var modalHeader = '_1ukx6mxf';
export var modalOverlay = '_1ukx6mxb';
export var modalVTScope = '_1ukx6mx3';
export var promptModalContent = '_1ukx6mxk';
export var widthVar = 'var(--_1ukx6mx0)';