import "../core/src/components/affine/setting-modal/general-setting/editor/style.css.ts.vanilla.css!=!../../../../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../core/src/components/affine/setting-modal/general-setting/editor/style.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA61VwW7bMAy99yt0GdAcVCRp06XqbT+w626DYtE2O1nSJDpxOvTfB0txYztOmg29CRTJR/I9UXc/F7+foHqhOftzw1iuoeGFtzvBFs83jCkMTsu9iBet4aUOhPmeZ9YQGEoXHIxqLys0fIeKSsEWq7mLAZVsOtsy2d5u7jrQxSeDzq8BXUbQjcx+Fd7WRgm2K5GgjRvGpDwlYFGSYPfzbdna7BZ8ru2O7wWTNdlB7vuYm6AhTl6akFtfCZZJhyQ1vkaQ3Briu0PWx/n8FLiX8CEmdDYgoTWCedCScAuTc4pjUeghS86Z1XVlUiO+QMM3lshWgi0fRjCrCDPyWqchxHoDvoJgW+lvOZd5jgZ4slezk55WqafMautHMXEyATJrlPT72aCGx1GrchOsrhMxZJ1gMauGnOKxF/k1Rh5F8KV17HhbrA9kbqxX4AVbuIYFq1ENS9NyD56jCajgW3TlKWJ2DOZeKqyDYA8p5cdKzcAQ+PZKaiwMR4IqHM29JtYX2u8YWSwTbhrCKjXaE1o8aknw45av5l9mH9LXhNnVVPXfDJ+KSBPsOZXtW/m+Ba9lSuCkUmgKwZau6eT1PkM0OtZ1GOXkvC5OeJqjV45GQXPYLZ0qTl7AU9oKtuGhlKrdRcYa+J+uCTzhibplzH8imDNdvg/q0MRI29MLMZD01F4X0gn2OGpwM7lru0fRNWtralk4Gq5+/bmsUEcaS/BIPVn1LKezjJJ10oOhYblCOC0zKK1W4GPtZ1XacxwOPTsbh5k13HmsOm33+lyOl7Ca5u6EheBkBnwDtAMwF9jtvhDBSlQquQ4Y7mHDNPb5VT/AHOpiErf9+3isvCV956V77r6woz9ojS5gGJSWD0u77v2eJ7FHxkju/1rjdKfXKDl20O0IY30l9WBrLEfKyO/eN+RliQ4X6TU7+R1zrMbiLNKmJhrJ+vClpw9jPcpUfnbNw7X19hemmrrkXQoAAA==\"}!../../../../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var InputContainer = '_1q9emjta';
export var fontItem = '_1q9emjte';
export var fontItemContainer = '_1q9emjtd';
export var fontLabel = '_1q9emjtf';
export var menu = '_1q9emjt2';
export var menuTrigger = '_1q9emjt3';
export var notFound = '_1q9emjth';
export var preViewLabelWrapper = '_1q9emjt1';
export var searchIcon = '_1q9emjtc';
export var searchInput = '_1q9emjtb';
export var selectedIcon = '_1q9emjtg';
export var settingWrapper = '_1q9emjt0';
export var shapeIndicator = '_1q9emjt9';
export var snapshot = '_1q9emjt7';
export var snapshotContainer = '_1q9emjt4';
export var snapshotLabel = '_1q9emjt8';
export var snapshotSkeleton = '_1q9emjt6';
export var snapshotTitle = '_1q9emjt5';